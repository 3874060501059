import React, { createContext, useContext, useEffect, useReducer } from 'react';
import { checkUserAuth } from 'api/user';
import LoadingPage from 'containers/LoadingPage/LoadingPage';
import reducer, { checkAuth, logout } from 'reducers/authReducer';

import { authStorage } from 'utils';

const initialState = {
  token: null,
  isAuthenticating: false,
  isAuthenticated: false,
  userType: null,
  ready: false
};

export const AuthContext = createContext(initialState);

const AuthProvider = ({ children }) => {
  const AuthContextValue = useContext(AuthContext);
  const [state, dispatch] = useReducer(reducer, AuthContextValue);

  useEffect(() => {
    if (!authStorage.getToken()) {
      dispatch(logout());
    } else {
      (async () => {
        try {
          dispatch(checkAuth(await checkUserAuth(0)));
        } catch (e) {
          dispatch(logout());
        }
      })();
    }
  }, []);

  return (
    <AuthContext.Provider value={{ state, dispatch }}>
      {state.ready ? children : <LoadingPage />}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
