import React from 'react';
import { Button, Container, Modal } from 'react-bootstrap';
import { ReactComponent as CloseSVG } from 'assets/svg/close.svg';

import AudioIcon from 'components/UI/AudioIcon/AudioIcon';
import { clsx } from 'utils';
import classes from './Modal.module.scss';
const CustomModal = ({
  title,
  audioLabel = null,
  name,
  onHide,
  size = 'xl',
  show = false,
  className = '',
  ...props
}) => {
  const modelID = `tbibcom-modal--${name}`;

  return (
    <Modal
      dialogClassName={clsx(className, classes.modal)}
      size={size}
      onHide={onHide}
      show={show}
      {...props}
      aria-labelledby={modelID}
    >
      <Modal.Header className="border-bottom-0">
        <Modal.Title className={classes.title} id={modelID}>
          {title}
          {audioLabel && <AudioIcon label={audioLabel} />}
        </Modal.Title>
        <Button onClick={onHide} className="p-0" variant="light">
          <CloseSVG height="30" />
        </Button>
      </Modal.Header>
      <Modal.Body as={Container}>{props.children}</Modal.Body>
    </Modal>
  );
};

export default CustomModal;
