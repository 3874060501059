import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { defineMessages, useIntl } from 'react-intl';

import AudioIcon from 'components/UI/AudioIcon/AudioIcon';
import classes from './StepOne.module.scss';
import { AgeToggle } from './SvgButtons/AgeToggle';
import { CancerTypeToggle } from './SvgButtons/CancerTypeToggle';
import { GenderToggle } from './SvgButtons/GenderToggle';

const intlMessages = defineMessages({
  sex: {
    id: 'survey.sex',
    defaultMessage: 'Sexe'
  },
  cancerType: {
    id: 'survey.cancerType',
    defaultMessage: 'Type de cancer'
  },
  age: {
    id: 'survey.age',
    defaultMessage: 'Age'
  }
});

const StepOne = ({ saveResults }) => {
  const { formatMessage } = useIntl();

  return (
    <Container fluid className={classes.main}>
      <Row>
        <Col>
          <span className={classes.questionTitle}>
            {formatMessage(intlMessages.sex)}
          </span>
          <AudioIcon label="sex" />
        </Col>
        <Col sm={3} lg={9}>
          <GenderToggle saveResults={saveResults} />
        </Col>
      </Row>
      <Row>
        <Col>
          <span className={classes.questionTitle}>
            {formatMessage(intlMessages.cancerType)}
          </span>
          <AudioIcon label="cancerType" />
        </Col>
        <Col sm={4} lg={9}>
          <CancerTypeToggle saveResults={saveResults} />
        </Col>
      </Row>
      <Row>
        <Col>
          <span className={classes.questionTitle}>
            {formatMessage(intlMessages.age)}
          </span>
          <AudioIcon label="age" />
        </Col>
        <Col sm={3} lg={9}>
          <AgeToggle saveResults={saveResults} />
        </Col>
      </Row>
    </Container>
  );
};

export default StepOne;
