// This optional code is used to register a service worker.
// register() is not called by default.

// This lets the app load faster on subsequent visits in production, and gives
// it offline capabilities. However, it also means that developers (and users)
// will only see deployed updates on subsequent visits to a page, after all the
// existing tabs open on the page have been closed, since previously cached
// resources are updated in the background.

// To learn more about the benefits of this model and instructions on how to
// opt-in, read https://bit.ly/CRA-PWA

export function register() {
  // process.env.NODE_ENV === 'production' &&
  if ('serviceWorker' in navigator) {
    window.addEventListener('load', () => {
      // registerValidSW('sw.js');
    });
  }
}

function registerValidSW(swUrl) {
  navigator.serviceWorker
    .register(`${process.env.PUBLIC_URL}/${swUrl}`, { scope: '/ ' })
    .then(registration => {
      console.log('registred', registration);
    })
    .catch(error => {
      console.error('Error during service worker registration:', error);
    });
}

export function unregister() {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.ready
      .then(registration => {
        registration.unregister();
      })
      .catch(error => {
        console.error(error.message);
      });
  }
}
