import React, { useContext } from 'react';
import { Nav, Navbar } from 'react-bootstrap';
import { IndexLinkContainer } from 'react-router-bootstrap';
import { withRouter } from 'react-router-dom';
import { ReactComponent as GearSVG } from 'assets/svg/gearSvg.svg';
import { ReactComponent as HomeSVG } from 'assets/svg/home.svg';
import { ReactComponent as FlspcSVG } from 'assets/svg/logo-flspc.svg';
import { ReactComponent as LogoutSVG } from 'assets/svg/red_lock.svg';

import { AuthContext } from 'contexts/AuthProvider';
import { clsx } from 'utils';
import LocalesToggle from './LocalesToggle';
import classes from './Toolbar.module.scss';

const Toolbar = () => {
  const { state } = useContext(AuthContext);
  return (
    <Navbar
      dir="ltr"
      as="header"
      role="navigation"
      className={clsx(classes.root, 'fixed-top')}
      expand="lg"
    >
      <IndexLinkContainer to="/">
        <Navbar.Brand className={classes.logo}>
          <FlspcSVG />
        </Navbar.Brand>
      </IndexLinkContainer>

      <Nav as="nav" role="navigation" className={classes.innerWrapper}>
        <LocalesToggle />
        {state.isAuthenticated && (
          <React.Fragment>
            {state.userType != 'PATIENT' && (
              <IndexLinkContainer
                className={classes.desktopMenu}
                to="/updatePassword"
                exact
              >
                <Nav.Link data-link-name="home">
                  <GearSVG />
                </Nav.Link>
              </IndexLinkContainer>
            )}
            <IndexLinkContainer
              className={classes.desktopMenu}
              to={state.userType === 'PATIENT' ? '/' : '/dashboard'}
              exact
            >
              <Nav.Link data-link-name="home">
                <HomeSVG />
              </Nav.Link>
            </IndexLinkContainer>
            <IndexLinkContainer
              className={classes.desktopMenu}
              to="/logout"
              exact
            >
              <Nav.Link data-link-name="logout">
                <LogoutSVG />
              </Nav.Link>
            </IndexLinkContainer>
          </React.Fragment>
        )}
      </Nav>
    </Navbar>
  );
};

export default withRouter(Toolbar);
