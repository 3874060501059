import { useContext, useEffect, useRef, useState } from 'react';
import { fetchContent } from 'api/content';

import { appContext } from 'contexts/AppProvider';

function useContent(contentName) {
  const {
    state: { locale }
  } = useContext(appContext);
  const [state, setState] = useState({
    content: '',
    isFetching: false
  });
  const ref = useRef(null);

  useEffect(() => {
    if (state.content) {
      ref.current.innerHTML = state.content;
    }
  }, [state.content]);

  useEffect(() => {
    getContent();
    async function getContent() {
      setState(prev => ({
        ...prev,
        isFetching: true
      }));
      try {
        const { content } = await fetchContent({
          locale,
          content: { name: contentName }
        });
        setState({
          content,
          isFetching: false
        });
      } catch (error) {
        setState(prev => ({
          ...prev,
          isFetching: false
        }));
      }
    }
  }, [contentName, locale]);

  return ref;
}
export default useContent;
