import React, { useEffect } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { ReactComponent as FemmeSVG } from 'assets/svg/femme.svg';
import { ReactComponent as HommeSVG } from 'assets/svg/homme.svg';

import AudioIcon from 'components/UI/AudioIcon/AudioIcon';
import BaseSvgButton from './BaseSvgButton';
import useToggle from './useToggle';

const intlMessages = defineMessages({
  homme: {
    id: 'Survey.toggle.homme',
    defaultMessage: 'homme'
  },
  femme: {
    id: 'Survey.toggle.femme',
    defaultMessage: 'femme'
  }
});

const HommeSvgBtn = ({ onClick, selected }) => {
  const { formatMessage } = useIntl();

  return (
    <BaseSvgButton
      title={formatMessage(intlMessages.homme)}
      onClick={onClick}
      selected={selected}
      SVGItem={HommeSVG}
    >
      <AudioIcon label="man" />
    </BaseSvgButton>
  );
};

const FemmeSvgBtn = ({ onClick, selected }) => {
  const { formatMessage } = useIntl();

  return (
    <BaseSvgButton
      title={formatMessage(intlMessages.femme)}
      onClick={onClick}
      selected={selected}
      SVGItem={FemmeSVG}
    >
      <AudioIcon label="women" />
    </BaseSvgButton>
  );
};

export const GenderToggle = ({ saveResults }) => {
  const { handleToggleClick, toggleState } = useToggle({
    HOMME: false,
    FEMME: false
  });
  useEffect(() => {
    saveResults(prev => {
      return { ...prev, gender: toggleState };
    });
  }, [saveResults, toggleState]);
  return (
    <React.Fragment>
      <HommeSvgBtn
        onClick={() => handleToggleClick({ HOMME: !toggleState['HOMME'] })}
        selected={toggleState['HOMME']}
      />
      <FemmeSvgBtn
        onClick={() => handleToggleClick({ FEMME: !toggleState['FEMME'] })}
        selected={toggleState['FEMME']}
      />
    </React.Fragment>
  );
};
