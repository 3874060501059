import { useContext, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import qs from 'qs';

import { appContext, setLocale } from 'contexts/AppProvider';

const LangSetter = () => {
  const location = useLocation();
  const history = useHistory();
  const {
    state: { locale },
    dispatch
  } = useContext(appContext);

  useEffect(() => {
    preserveLocale();

    function preserveLocale() {
      const searchParams = qs.parse(location.search, {
        ignoreQueryPrefix: true
      });

      /**
       * if no locale search param is set we set it
       * and return
       */
      if (!searchParams.locale) {
        searchParams.locale = locale;
        const search = qs.stringify(searchParams, { addQueryPrefix: true });
        history.replace({ ...location, search });
        return;
      }
      /**
       * if current locale search param is different than
       * locale prop, we set the locale state with
       * search param
       */
      if (searchParams.locale !== locale) {
        dispatch(setLocale(searchParams.locale));
      }
    }
  }, [dispatch, history, locale, location]);

  useEffect(() => {
    document.documentElement.lang = locale;
    document.body.dir = locale === 'ar' ? 'rtl' : 'ltr';
  }, [locale]);

  return null;
};

export default LangSetter;
