import request from './axiosRequest';

export const fetchContent = ({ locale, name }) => {
  return request({
    url: '/content/show',
    method: 'GET',
    params: { content: { name }, locale }
  });
};

export const fetchQuestions = ({ locale }) => {
  return request({
    url: '/content/questions',
    method: 'GET',
    params: { locale }
  });
};
