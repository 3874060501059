import React, { useEffect } from 'react';
import { ReactComponent as Happier } from 'assets/svg/happier.svg';
import { ReactComponent as Happy } from 'assets/svg/happy.svg';
import { ReactComponent as Sad } from 'assets/svg/sad.svg';

import { clsx } from 'utils';
import BaseSvgButton from './BaseSvgButton';
import classes from './SmileyToggle.module.scss';
import useToggle from './useToggle';

const HappierSvgBtn = ({ onClick, selected }) => {
  return (
    <BaseSvgButton
      onClick={onClick}
      selected={selected}
      SVGItem={Happier}
      svgClassName={classes.colorSvg}
      selectedClassName={clsx(classes.selectedSvg, classes.happier)}
    ></BaseSvgButton>
  );
};
const HappySvgBtn = ({ onClick, selected }) => {
  return (
    <BaseSvgButton
      onClick={onClick}
      selected={selected}
      SVGItem={Happy}
      svgClassName={classes.colorSvg}
      selectedClassName={clsx(classes.selectedSvg, classes.happy)}
    ></BaseSvgButton>
  );
};
const SadSvgBtn = ({ onClick, selected }) => {
  return (
    <BaseSvgButton
      onClick={onClick}
      selected={selected}
      SVGItem={Sad}
      svgClassName={classes.colorSvg}
      selectedClassName={clsx(classes.selectedSvg, classes.sad)}
    ></BaseSvgButton>
  );
};

export const SmileyToggle = ({ name, saveResults }) => {
  const { handleToggleClick, toggleState } = useToggle({
    HAPPY: false,
    HAPPIER: false,
    SAD: false
  });

  useEffect(() => {
    saveResults(prev => {
      let elem = {};
      elem[name] = toggleState;
      return { ...prev, ...elem };
    });
  }, [saveResults, toggleState]);

  return (
    <React.Fragment>
      <HappierSvgBtn
        onClick={() => handleToggleClick({ HAPPIER: !toggleState['HAPPIER'] })}
        selected={toggleState['HAPPIER']}
      />
      <HappySvgBtn
        onClick={() => handleToggleClick({ HAPPY: !toggleState['HAPPY'] })}
        selected={toggleState['HAPPY']}
      />
      <SadSvgBtn
        onClick={() => handleToggleClick({ SAD: !toggleState['SAD'] })}
        selected={toggleState['SAD']}
      />
    </React.Fragment>
  );
};
