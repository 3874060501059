import React, { useEffect, useRef, useState } from 'react';
import { Button, Spinner } from 'react-bootstrap';
import PropTypes from 'prop-types';

import { clsx } from 'utils';
import classes from './LoadingButton.module.scss';

const LoadingButton = ({
  isLoading = false,
  isDisabled = false,
  onClick = () => {},
  keepWidth = true,
  children,
  variant = 'secondary',
  className,
  ...props
}) => {
  const [width, setWidth] = useState(null);
  const ref = useRef(null);

  useEffect(() => {
    if (!isLoading) {
      setWidth(ref.current.offsetWidth);
    }
  }, [isLoading, width]);

  const style = {};

  if (keepWidth && width) {
    style.minWidth = width;
  }

  return (
    <Button
      className={clsx(classes.root, className)}
      style={style}
      ref={ref}
      variant={variant}
      disabled={isLoading && isDisabled}
      onClick={!isLoading ? onClick : null}
      {...props}
    >
      {isLoading ? (
        <Spinner animation="border" role="status" size="sm">
          <span className="sr-only">Loading...</span>
        </Spinner>
      ) : (
        children
      )}
    </Button>
  );
};

LoadingButton.propTypes = {
  isLoading: PropTypes.bool,
  onClick: PropTypes.func,
  keepWidth: PropTypes.bool
};

export default LoadingButton;
