import React, { useEffect } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { ReactComponent as MoreThan60SVG } from 'assets/svg/age60plus.svg';
import { ReactComponent as UpTo40SVG } from 'assets/svg/age2040.svg';
import { ReactComponent as UpTo60SVG } from 'assets/svg/age4060.svg';

import AudioIcon from 'components/UI/AudioIcon/AudioIcon';
import BaseSvgButton from './BaseSvgButton';
import useToggle from './useToggle';

const intlMessages = defineMessages({
  upTo40: {
    id: 'Survey.toggle.upTo40',
    defaultMessage: '20-40 ans'
  },
  upTo60: {
    id: 'Survey.toggle.upTo60',
    defaultMessage: '40-60 ans'
  },
  moreThan60: {
    id: 'Survey.toggle.moreThan60',
    defaultMessage: '> 60 ans'
  }
});

const UpTo40SvgBtn = ({ onClick, selected }) => {
  const { formatMessage } = useIntl();

  return (
    <BaseSvgButton
      title={formatMessage(intlMessages.upTo40)}
      onClick={onClick}
      selected={selected}
      SVGItem={UpTo40SVG}
    >
      <br />
      <AudioIcon label="age2040" />
    </BaseSvgButton>
  );
};

const UpTo60SvgBtn = ({ onClick, selected }) => {
  const { formatMessage } = useIntl();

  return (
    <BaseSvgButton
      title={formatMessage(intlMessages.upTo60)}
      onClick={onClick}
      selected={selected}
      SVGItem={UpTo60SVG}
    >
      <br /> <AudioIcon label="age4060" />
    </BaseSvgButton>
  );
};

const MoreThan60SvgBtn = ({ onClick, selected }) => {
  const { formatMessage } = useIntl();

  return (
    <BaseSvgButton
      title={formatMessage(intlMessages.moreThan60)}
      onClick={onClick}
      selected={selected}
      SVGItem={MoreThan60SVG}
    >
      <br /> <AudioIcon label="ageMore60" />
    </BaseSvgButton>
  );
};

export const AgeToggle = ({ saveResults }) => {
  const { handleToggleClick, toggleState } = useToggle({
    OLDER: false,
    OLD: false,
    AVG: false
  });

  useEffect(() => {
    saveResults(prev => {
      return { ...prev, age: toggleState };
    });
  }, [saveResults, toggleState]);

  return (
    <React.Fragment>
      <UpTo40SvgBtn
        onClick={() => handleToggleClick({ AVG: !toggleState['AVG'] })}
        selected={toggleState['AVG']}
      />
      <UpTo60SvgBtn
        onClick={() => handleToggleClick({ OLD: !toggleState['OLD'] })}
        selected={toggleState['OLD']}
      />
      <MoreThan60SvgBtn
        onClick={() => handleToggleClick({ OLDER: !toggleState['OLDER'] })}
        selected={toggleState['OLDER']}
      />
    </React.Fragment>
  );
};
