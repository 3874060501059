/**
 * Concatenates an indefinite number of strings
 * (classNames) into one
 * @returns {String}
 */
export const clsx = (...rest) =>
  [...rest]
    // only keep strings
    .filter(s => typeof s === 'string' || s instanceof String)
    .join(' ')
    .trim();

/**
 * Utility functions to help persist jwt token
 * to local storage
 * Tweak it as needed for your application
 */
export const authStorage = {
  getToken: () => {
    return localStorage.getItem('token');
  },
  /**
   * Removes auth data (token & token expiration date)
   * from local storage
   */
  clear: () => {
    localStorage.removeItem('token');
    localStorage.removeItem('tokenExpDate');
    localStorage.removeItem('userType');
    localStorage.removeItem('locale');
  },

  /**
   * persist auth data (token & token expiration date) to
   * local storage
   * @param {String} token - auth access token
   * @param {Number} tokenExp
   */
  persist: (token, expriseIn, user_type) => {
    // expiration date is current time + expires_in
    const tokenExpDate = new Date(new Date().getTime() + expriseIn * 1000);
    localStorage.setItem('token', token);
    localStorage.setItem('tokenExpDate', tokenExpDate);
    localStorage.setItem('userType', user_type);
  },
  setLocale: locale => {
    localStorage.setItem('locale', locale);
  },
  getLocale: () => {
    localStorage.getItem('locale');
  }
};

/**
 * Delays the mounting of a lazy loaded
 * component for a (minimum) period of time
 * @param {Function} importFunct - Same function React.lazy receives
 * @param {Number} delay - the period of time you want to defer the component (seconds)
 * @returns {Function} - a function received by React.lazy
 */
export const deferedLoading = (importFunc, delay = 250) => {
  return () =>
    Promise.all([
      importFunc(),
      new Promise(resolve => setTimeout(resolve, delay))
    ]).then(([moduleExports]) => moduleExports);
};

export const deferRequest = async (requestFn, delay = 250) => {
  return Promise.all([
    requestFn(),
    new Promise(resolve => setTimeout(resolve, delay))
  ]).then(([res]) => res);
};

/**
 *
 * @param {Number} min - Minimum number in range (inclusive)
 * @param {Number} max - Maximum number in range (inclusive)
 */
export const randomInt = (min, max) =>
  Math.floor(Math.random() * (max - min + 1)) + min;

/**
 * Returns a random item froma given array
 * @param {Array} arr - array of items to randomly select an element from
 */
export const randomItem = arr => arr[Math.floor(Math.random() * arr.length)];

/**
 * Checks if the current devise is touch
 */
export function istouchDevice() {
  const prefixes = ' -webkit- -moz- -o- -ms- '.split(' ');
  const mq = function (query) {
    return window.matchMedia(query).matches;
  };

  if (
    'ontouchstart' in window ||
    // eslint-disable-next-line no-undef
    (window.DocumentTouch && document instanceof DocumentTouch)
  ) {
    return true;
  }
  // include the 'heartz' as a way to have a non matching MQ to help terminate the join
  // https://git.io/vznFH
  const query = ['(', prefixes.join('touch-enabled),('), 'heartz', ')'].join(
    ''
  );
  return mq(query);
}

/**
 * Shuffles array in place. ES6 version
 * @param {Array} arr items An array containing the items.
 */
export const shuffle = arr => {
  for (let i = arr.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    // we swap item at position 'i' with item at position 'j'
    // this is Array Destructuring
    [arr[i], arr[j]] = [arr[j], arr[i]];
  }
  return arr;
};

/**
 * Bool to check if device is mobile
 */
export const isMobile = /Mobi|Android/i.test(navigator.userAgent);

export const groupBy = (xs, key) => {
  return xs.reduce((previous, current) => {
    (previous[current[key]] = previous[current[key]] || []).push(current);
    return previous;
  }, {});
};

export const getValuesFromObjectByKey = (obj, key) => {
  const result = new Set();
  obj.map(item => {
    result.add(item[key]);
  });
  return [...result];
};
