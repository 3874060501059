import React, { useEffect } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { ReactComponent as ColonSVG } from 'assets/svg/colon.svg';
import { ReactComponent as RectumSVG } from 'assets/svg/rectum.svg';

import AudioIcon from 'components/UI/AudioIcon/AudioIcon';
import BaseSvgButton from './BaseSvgButton';
import useToggle from './useToggle';

const intlMessages = defineMessages({
  colon: {
    id: 'Survey.toggle.colon',
    defaultMessage: 'Colon'
  },
  rectum: {
    id: 'Survey.toggle.rectum',
    defaultMessage: 'Rectum'
  }
});

const ColonSvgBtn = ({ onClick, selected }) => {
  const { formatMessage } = useIntl();

  return (
    <BaseSvgButton
      title={formatMessage(intlMessages.colon)}
      onClick={onClick}
      selected={selected}
      SVGItem={ColonSVG}
    >
      <AudioIcon label="colon" />
    </BaseSvgButton>
  );
};

const RectumSvgBtn = ({ onClick, selected }) => {
  const { formatMessage } = useIntl();

  return (
    <BaseSvgButton
      title={formatMessage(intlMessages.rectum)}
      onClick={onClick}
      selected={selected}
      SVGItem={RectumSVG}
    >
      <AudioIcon label="rectum" />
    </BaseSvgButton>
  );
};

export const CancerTypeToggle = ({ saveResults }) => {
  const { handleToggleClick, toggleState } = useToggle({
    COLON: false,
    RECTUM: false
  });
  useEffect(() => {
    saveResults(prev => {
      return { ...prev, cancerType: toggleState };
    });
  }, [saveResults, toggleState]);

  return (
    <React.Fragment>
      <ColonSvgBtn
        onClick={() => handleToggleClick({ COLON: !toggleState['COLON'] })}
        selected={toggleState['COLON']}
      />
      <RectumSvgBtn
        onClick={() => handleToggleClick({ RECTUM: !toggleState['RECTUM'] })}
        selected={toggleState['RECTUM']}
      />
    </React.Fragment>
  );
};
