import React from 'react';
import { ReactComponent as SpeakerIcon } from 'assets/svg/games/speaker.svg';

import { useAudio, useGetAudio } from 'hooks';
import { clsx } from 'utils';
import classes from './AudioIcon.module.scss';
const AudioIcon = ({ label = null, videoId = null }) => {
  const { fetched, audioUrl: url } = useGetAudio(label, videoId);
  const { audioPlaying, onPlayButtonClick } = useAudio(url, false);
  return (
    <React.Fragment>
      {fetched && (
        <button
          role="switch"
          aria-checked={audioPlaying}
          aria-label="toggle audio"
          className={clsx(classes.playButton, audioPlaying && classes.checked)}
          onClick={onPlayButtonClick}
        >
          <SpeakerIcon className={classes.speakerIcon} />
        </button>
      )}
    </React.Fragment>
  );
};

export default AudioIcon;
