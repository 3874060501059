import request from './axiosRequest';

export const fetchAudio = (label, locale) => {
  return request({
    url: '/audios/show',
    method: 'GET',
    params: { label, locale }
  });
};

export const fetchVideoAudio = (idVideo, locale) => {
  return request({
    url: `/audios/video`,
    method: 'GET',
    params: { idVideo, locale }
  });
};
