import { authStorage } from 'utils';

const AUTH_START = 'AUTH_START';
const AUTH_SUCCESS = 'AUTH_SUCCESS';
const AUTH_FAIL = 'AUTH_FAIL';
const LOGOUT = 'LOGOUT';
const UPDATE_USER = 'UPDATE_USER';
const CHECK_AUTH = 'CHECK_AUTH';

const createAction = (type, payload) => ({
  type,
  payload
});

export const authStart = () => createAction(AUTH_START);
export const authFail = payload => createAction(AUTH_FAIL, payload);
export const authSuccess = payload => createAction(AUTH_SUCCESS, payload);
export const logout = payload => createAction(LOGOUT, payload);
export const updateUser = () => createAction(UPDATE_USER);
export const checkAuth = payload => createAction(CHECK_AUTH, payload);

export default function (state, { type, payload }) {
  const change = obj => ({ ...state, ...obj });
  switch (type) {
    case AUTH_START:
      return change({
        isAuthenticating: true,
        ready: false
      });
    case AUTH_SUCCESS:
      authStorage.persist(payload.token, payload.expires_in, payload.user_type);
      return change({
        isAuthenticating: false,
        token: payload.token,
        isAuthenticated: true,
        userType: payload.user_type || null,
        ready: true
      });
    case AUTH_FAIL:
      return change({
        isAuthenticating: false,
        ready: true
      });
    case LOGOUT:
      localStorage.clear();
      return change({
        token: null,
        isAuthenticated: false,
        isAuthenticating: false,
        ready: true
      });
    case CHECK_AUTH: {
      const token = authStorage.getToken();
      return change({
        token: token || false,
        isAuthenticated: !!token || false,
        isAuthenticating: false,
        userType: payload.user_type || null,
        ready: true
      });
    }
    default:
      throw new Error();
  }
}
