import React from 'react';

import './ToggleButton.module.scss';

const ToggleButton = ({ children, handleToggle, current = 'ar' }) => {
  const activeChild = children.filter(child => {
    return child.props.locale === current;
  });

  return (
    <div
      className="toggle-container"
      onClick={() => handleToggle(activeChild[0].props.switchto)}
      onKeyDown={() => {}}
      role="button"
      tabIndex="0"
    >
      <div className={`dialog-button`}>{activeChild}</div>
    </div>
  );
};

export default ToggleButton;
