import React from 'react';
import { ReactComponent as SurveySVG } from 'assets/svg/surveyBtn.svg';

import LoadingButton from 'components/UI/LoadingButton/LoadingButton';
import classes from './SurveyBtn.module.scss';

const SurveyBtn = ({ onClick, className, showSurveyIcon }) => {
  return (
    <LoadingButton
      variant="link"
      className={showSurveyIcon ? className : classes.hide}
      onClick={onClick}
      keepWidth={false}
    >
      <SurveySVG />
    </LoadingButton>
  );
};

export default SurveyBtn;
