import React, { useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { setUserConsent } from 'api/survey';
import { setStep1Results, setStep2Results } from 'api/survey';

import Modal from 'components/Modal/Modal';
import AudioIcon from 'components/UI/AudioIcon/AudioIcon';
import LoadingButton from 'components/UI/LoadingButton/LoadingButton';
import ConsentText from './ConsentText/ConsentText';
import StepOne from './Steps/StepOne';
import StepTwo from './Steps/StepTwo';
import classes from './Survey.module.scss';

const intlMessages = defineMessages({
  title: {
    id: 'survey.title',
    defaultMessage: 'Questionnaire de satisfaction'
  },
  validate: {
    id: 'survey.validate',
    defaultMessage: 'Valider'
  },
  accept: {
    id: 'survey.accept',
    defaultMessage: "J'accepte"
  }
});
const [INIT, STEP_1, STEP_2, CLOSE] = [0, 1, 2, 3];

const Survey = ({ showSurveyModal, setShowSurveyModal }) => {
  const { formatMessage } = useIntl();
  const [surveyStep, setSurveyStep] = useState(INIT);
  // const [surveyResults, setSurveyResults] = useState(null);
  const [firstStepResults, setFirstStepResults] = useState(null);
  const [secondStepResults, setSecondStepResults] = useState(null);

  const getKeyWhenTrue = obj => {
    if (obj)
      for (const [key, value] of Object.entries(obj)) {
        if (value) return key;
      }
    return null;
  };
  const saveSurveyResults = step => {
    if (step === STEP_1) {
      const age = getKeyWhenTrue(firstStepResults.age);
      const cancerType = getKeyWhenTrue(firstStepResults.cancerType);
      const gender = getKeyWhenTrue(firstStepResults.gender);

      setStep1Results({ age, gender, cancerType });
    }
    if (step === STEP_2) {
      const Q1 = getKeyWhenTrue(secondStepResults.Question1);
      const Q2 = getKeyWhenTrue(secondStepResults.Question2);
      const Q3 = getKeyWhenTrue(secondStepResults.Question3);
      const Q4 = getKeyWhenTrue(secondStepResults.Question4);
      const Q5 = getKeyWhenTrue(secondStepResults.Question5);

      setStep2Results({ Q1, Q2, Q3, Q4, Q5 });
    }
  };

  const ShowStep = step => {
    switch (step) {
      case CLOSE:
      case INIT:
        return (
          <React.Fragment>
            <AudioIcon label="consent_content" />
            <ConsentText />
            <div className={classes.buttonContainer}>
              <LoadingButton
                onClick={async () => {
                  await setUserConsent();
                  // TODO breadcrumb consent saved !
                  setSurveyStep(STEP_1);
                }}
                className={classes.validateBtn}
              >
                <div>{formatMessage(intlMessages.accept)}</div>
                <AudioIcon label="accept" />
              </LoadingButton>
            </div>
          </React.Fragment>
        );
      case STEP_1:
        return (
          <React.Fragment>
            <StepOne saveResults={setFirstStepResults} />
            <div className={classes.buttonContainer}>
              <LoadingButton
                onClick={() => {
                  setSurveyStep(STEP_2);
                  saveSurveyResults(STEP_1);
                }}
                className={classes.validateBtn}
              >
                <div>{formatMessage(intlMessages.validate)}</div>
                <AudioIcon label="valider" />
              </LoadingButton>
            </div>
          </React.Fragment>
        );
      case STEP_2:
        return (
          <React.Fragment>
            <StepTwo saveResults={setSecondStepResults} />
            <div className={classes.buttonContainer}>
              <LoadingButton
                onClick={() => {
                  saveSurveyResults(STEP_2);
                  setShowSurveyModal(false);
                  setSurveyStep(CLOSE);
                }}
                className={classes.validateBtn}
              >
                <div>{formatMessage(intlMessages.validate)}</div>
                <AudioIcon label="valider" />
              </LoadingButton>
            </div>
          </React.Fragment>
        );

      default:
        return null;
    }
  };

  return (
    <Modal
      show={showSurveyModal}
      onHide={() => setShowSurveyModal(false)}
      name="survey-modal"
      title={formatMessage(intlMessages.title)}
      audioLabel="survey"
      className={classes.popupStyle}
      centered
    >
      {ShowStep(surveyStep)}
    </Modal>
  );
};

export default Survey;
