import request from './axiosRequest';

export const checkUserAuth = id => {
  return request({
    url: `/users/${id}`,
    method: 'GET'
  });
};

export const updateUserInfo = params => {
  return request({
    url: '/users/current',
    method: 'PUT',
    params: { user: { ...params } }
  });
};

export const fetchPatients = params => {
  const res = request({
    url: '/users',
    method: 'GET',
    params
  });

  return res;
};

export const fetchPatientVideos = (id, locale) => {
  return request({
    url: `/users/${id}/videosViews`,
    method: 'GET',
    params: {
      locale
    }
  });
};

export const updateVideoSequence = (userId, oldId, newIndex) => {
  return request({
    url: `/users/${userId}/sequence`,
    method: 'PUT',
    data: { oldId, newIndex }
  });
};

export const toggleUserActive = userId => {
  return request({
    url: `/users/${userId}/toggle_active`,
    method: 'PUT'
  });
};

export const toggleUserAlerte = userId => {
  return request({
    url: `/users/${userId}/toggle_alerte`,
    method: 'PUT'
  });
};

export const getAlterteStatus = () => {
  return request({
    url: `/users/is_alerte_active`,
    method: 'GET'
  });
};

export const fetchPatientInfo = userId => {
  return request({
    url: `/users/${userId}/infos`,
    method: 'GET'
  });
};

export const generatePass = () => {
  return request({
    url: `/users/generatePass`,
    method: 'GET'
  });
};

export const createPatient = ({ fileNumber, accessCode }) => {
  return request({
    url: `/users/create_patient`,
    method: 'PUT',
    data: {
      file_number: fileNumber,
      password: accessCode
    }
  });
};

export const editPatient = params => {
  return request({
    url: `/users/edit_patient/${params.id}`,
    method: 'PATCH',
    params: {
      user: {
        file_number: params.fileNumber,
        password: params.accessCode,
        care_center_id: params.centers[0].id
      }
    }
  });
};

export const fetchPatientVideosViews = (id, locale) => {
  return request({
    url: `/users/${id}/patientVideosViews`,
    method: 'GET',
    params: {
      locale
    }
  });
};

export const updateMedecinPassword = (oldPwd, newPwd) => {
  return request({
    url: `/users/update_password`,
    method: 'PUT',
    params: {
      old_password: oldPwd,
      new_password: newPwd
    }
  });
};
