import React, { Suspense } from 'react';
import { BrowserRouter } from 'react-router-dom';
import Layout from 'containers/Layout/Layout';
import LoadingPage from 'containers/LoadingPage/LoadingPage';
import Routes from 'routes';

import AuthProvider from 'contexts/AuthProvider';
import LangSetter from './components/LangSetter/LangSetter';
import AppProvider from './contexts/AppProvider';

const App = () => {
  return (
    <Suspense fallback={<LoadingPage />}>
      <AuthProvider>
        <AppProvider>
          <BrowserRouter>
            <LangSetter />
            <Layout>
              <Routes />
            </Layout>
          </BrowserRouter>
        </AppProvider>
      </AuthProvider>
    </Suspense>
  );
};

export default App;
