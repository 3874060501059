import React, { useContext } from 'react';
import { Route, Switch } from 'react-router-dom';

import { AuthenticatedRoute, UnauthenticatedRoute } from 'components/Routing';
import { AuthContext } from 'contexts/AuthProvider';

// lazy loaded routes
const Login = React.lazy(() => import('containers/Login/Login'));
const LoginMedecin = React.lazy(() =>
  import('containers/LoginMedecin/LoginMedecin')
);
const Videos = React.lazy(() => import('containers/Videos/Videos'));
const VideoPage = React.lazy(() => import('containers/VideoPage/VideoPage'));
const NotFound = React.lazy(() => import('containers/NotFound/NotFound'));
const Logout = React.lazy(() => import('containers/Logout/Logout'));
const Dashboard = React.lazy(() => import('containers/Dashboard/Dashboard'));
const UserCreate = React.lazy(() => import('containers/User/Create'));
const UserEdit = React.lazy(() => import('containers/User/tabs/Edit'));
const UserSettings = React.lazy(() => import('containers/User/Settings'));
const Charts = React.lazy(() => import('containers/Charts/Charts'));
const UserUpdatePass = React.lazy(() => import('containers/User/UpdatePass'));

export default () => {
  const { state } = useContext(AuthContext);
  return (
    <Switch>
      <AuthenticatedRoute
        isAuthenticated={state.isAuthenticated}
        path="/videos/:id"
        element={VideoPage}
        isExact={true}
      />
      <AuthenticatedRoute
        isAuthenticated={state.isAuthenticated}
        element={UserUpdatePass}
        path="/updatePassword"
        isExact={true}
      />
      <AuthenticatedRoute
        isAuthenticated={state.isAuthenticated}
        element={Dashboard}
        path="/dashboard"
        isExact={true}
      />
      <AuthenticatedRoute
        isAuthenticated={state.isAuthenticated}
        element={Dashboard}
        path="/stats"
        isExact={true}
      />
      <AuthenticatedRoute
        isAuthenticated={state.isAuthenticated}
        element={UserCreate}
        path="/new_user"
        isExact={true}
      />
      <AuthenticatedRoute
        isAuthenticated={state.isAuthenticated}
        element={UserEdit}
        path="/edit_user"
        isExact={true}
      />
      <AuthenticatedRoute
        isAuthenticated={state.isAuthenticated}
        element={UserSettings}
        path="/user_settings/:id"
        isExact={true}
      />
      <AuthenticatedRoute
        isAuthenticated={state.isAuthenticated}
        element={Charts}
        path="/charts"
        isExact={true}
      />
      <AuthenticatedRoute
        isAuthenticated={state.isAuthenticated}
        element={Videos}
        path="/videos"
        isExact={true}
      />
      <AuthenticatedRoute
        isAuthenticated={state.isAuthenticated}
        element={Logout}
        path="/logout"
      />
      <UnauthenticatedRoute
        isAuthenticated={state.isAuthenticated}
        path="/login"
        isExact={true}
        component={Login}
      />
      <UnauthenticatedRoute
        isAuthenticated={state.isAuthenticated}
        component={LoginMedecin}
        path="/medecin"
        isExact={true}
      />
      <AuthenticatedRoute
        isAuthenticated={state.isAuthenticated}
        element={Videos}
        path="/"
        isExact={true}
      />

      <Route path="*" component={NotFound} />
    </Switch>
  );
};
