import React, { useContext, useEffect, useState } from 'react';
import { fetchContent } from 'api/content';

import { appContext } from 'contexts/AppProvider';

const ConsentText = () => {
  const [consentText, SetConsentText] = useState('');

  const {
    state: { locale }
  } = useContext(appContext);

  useEffect(() => {
    (async () => {
      const data = await fetchContent({ name: 'CGU', locale });
      SetConsentText(data.content);
    })();
  }, [locale]);

  return (
    <div
      className="content"
      dangerouslySetInnerHTML={{ __html: consentText }}
    ></div>
  );
};

export default ConsentText;
