import React, { useContext, useEffect, useState } from 'react';
import { getAlterteStatus } from 'api/user';
import Survey from 'containers/Survey/Survey';

import Footer from 'components/Navigation/Footer/Footer';
import Toolbar from 'components/Navigation/Toolbar/Toolbar';
import SurveyBtn from 'components/UI/SurveyBtn/SurveyBtn';
import { AuthContext } from 'contexts/AuthProvider';
import classes from './Layout.module.scss';

const Layout = props => {
  const [showSurveyModal, setShowSurveyModal] = useState(false);

  const handleSurveyClick = () => {
    setShowSurveyModal(true);
  };
  const { state } = useContext(AuthContext);
  const { isAuthenticated, userType } = state;

  const showSurveyIcon = isAuthenticated && userType === 'PATIENT';

  useEffect(() => {
    if (isAuthenticated)
      (async () => {
        const { active } = await getAlterteStatus();
        setShowSurveyModal(active);
      })();
  }, [isAuthenticated]);
  return (
    <React.Fragment>
      <Toolbar />
      <main as="main" className={classes.main}>
        {props.children}
      </main>
      <SurveyBtn
        onClick={handleSurveyClick}
        className={classes.surveyBtn}
        showSurveyIcon={showSurveyIcon}
      />
      <Survey
        setShowSurveyModal={setShowSurveyModal}
        showSurveyModal={showSurveyModal}
      />
      <Footer />
    </React.Fragment>
  );
};

export default Layout;
