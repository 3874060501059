import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import qs from 'qs';

import ToggleButton from 'components/UI/ToggleButton/ToggleButton';
import { appContext } from 'contexts/AppProvider';
import { authStorage } from 'utils';

const LocalesToggle = () => {
  const location = useLocation();
  const history = useHistory();
  const {
    state: { locale }
  } = React.useContext(appContext);

  const setLocaleSearchParam = newLocale => {
    const searchParams = qs.parse(location.search, {
      ignoreQueryPrefix: true
    });
    searchParams.locale = newLocale;
    const search = qs.stringify(searchParams, { addQueryPrefix: true });

    history.push({ ...location, search });
    authStorage.setLocale(newLocale);
  };

  return (
    <ToggleButton handleToggle={setLocaleSearchParam} current={locale}>
      <img
        src={require('assets/arabic.png')}
        alt={`Arabe`}
        switchto="fr"
        locale="ar"
      />
      <img
        src={require('assets/french.png')}
        alt={`Français`}
        switchto="ar"
        locale="fr"
      />
    </ToggleButton>
  );
};

export default LocalesToggle;

// <WrapIf
// condition={locked}
// wrapper={children => (
//   <div className={classes.locked}>
//     {' '}
//     <LockSVG />
//     {children}{' '}
//   </div>
// )}
// >
