import React from 'react';

import LoadingButton from 'components/UI/LoadingButton/LoadingButton';
import { clsx } from 'utils';
import classes from './BaseSvgButton.module.scss';

const BaseSvgButton = ({
  title = null,
  onClick,
  selected,
  SVGItem,
  svgClassName = '',
  selectedClassName = '',
  children
}) => {
  return (
    <LoadingButton
      onClick={onClick}
      className={clsx(
        selected
          ? selectedClassName
            ? selectedClassName
            : classes.selected
          : '',
        svgClassName ? svgClassName : classes.svgButton
      )}
      keepWidth={false}
      variant="filter"
    >
      <div>
        <SVGItem />
      </div>
      {title ? <span>{title}</span> : ''}
      {children}
    </LoadingButton>
  );
};

export default BaseSvgButton;
