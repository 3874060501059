import { useState } from 'react';

export default function useToggle(initialState = {}) {
  const [toggleState, setToggleState] = useState(initialState);

  const handleToggleClick = choice => {
    const newState = { ...initialState, ...choice }; // choice = {"homme": true} as example
    setToggleState(newState);
  };

  return { handleToggleClick, toggleState };
}
