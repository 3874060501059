import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import PropTypes from 'prop-types';
import qs from 'qs';

const Logic = ({ Container, isAuthenticated, ...props }) => {
  const { redirect, ...searchParams } = qs.parse(props.location.search, {
    ignoreQueryPrefix: true
  });
  return !isAuthenticated ? (
    <Container {...props} />
  ) : (
    <Redirect
      to={{
        pathname: redirect || '/dashboard',
        search: qs.stringify(searchParams, { addQueryPrefix: true })
      }}
    />
  );
};

const unauthenticatedRoute = ({ component: C, isAuthenticated, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props => (
        <Logic Container={C} isAuthenticated={isAuthenticated} {...props} />
      )}
    />
  );
};

unauthenticatedRoute.propTypes = {
  isAuthenticated: PropTypes.bool,
  component: PropTypes.oneOfType([PropTypes.element, PropTypes.elementType])
};

export default unauthenticatedRoute;
