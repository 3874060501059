import React from 'react';
import { Container } from 'react-bootstrap';
import { defineMessages, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';

import { clsx } from 'utils';
import classes from './Footer.module.scss';

const intlMessages = defineMessages({
  legalMentions: {
    id: 'Footer.legalMentions',
    defaultMessage: 'Conditions générales'
  },
  contact: {
    id: 'Footer.contact',
    defaultMessage: 'Contact'
  },
  rights: {
    id: 'Footer.rights',
    defaultMessage: 'Tous droits réservés'
  }
});

const Footer = () => {
  const { formatMessage } = useIntl();

  return (
    <footer className={classes.root}>
      <div className={classes.background} />
      <Container
        className={clsx(
          'd-flex justify-content-between',
          classes.footerContents
        )}
        fluid
      >
        <ul className="mb-3 mr-n3">
          <li className="m-0 mr-3 d-inline-block">
            <Link to="#">{formatMessage(intlMessages.legalMentions)}</Link>
          </li>
          {/* <li className="m-0 mr-3 d-inline-block">
            <Link to="/pages/faq">{formatMessage(intlMessages.faq)}</Link>
          </li> */}
          <li className="m-0 mr-3 d-inline-block">
            <Link to="#">{formatMessage(intlMessages.contact)}</Link>
          </li>
        </ul>
        <p className="text-center m-0">{formatMessage(intlMessages.rights)}</p>
      </Container>
    </footer>
  );
};

export default Footer;
