import React from 'react';
import { Redirect, Route } from 'react-router-dom';
const Logic = ({ Container, isAuthenticated, ...props }) => {
  return isAuthenticated ? (
    <Container {...props} />
  ) : (
    <Redirect
      to={{
        pathname: '/login',
        state: { from: props.location }
      }}
    />
  );
};
const AuthenticatedRoute = ({
  element: Container,
  isAuthenticated,
  path,
  isExact
}) => {
  return (
    <Route
      path={path}
      isExact={isExact}
      render={props => (
        <Logic
          Container={Container}
          isAuthenticated={isAuthenticated}
          {...props}
        />
      )}
    />
  );
};
export default AuthenticatedRoute;
