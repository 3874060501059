import { useContext, useEffect, useState } from 'react';
import { fetchAudio } from 'api/audios';
import { fetchVideoAudio } from 'api/audios';

import { appContext } from 'contexts/AppProvider';

export default function useGetAudio(label, id = null) {
  const [audioUrl, setAudioUrl] = useState(null);
  const {
    state: { locale }
  } = useContext(appContext);
  useEffect(() => {
    (async () => {
      const data = label
        ? await fetchAudio(label, locale)
        : await fetchVideoAudio(id, locale);
      setAudioUrl(data.audio);
    })();
  }, [setAudioUrl, locale, id, label]);

  return { fetched: !!audioUrl, audioUrl };
}
