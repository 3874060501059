import React, { useContext, useReducer } from 'react';
import { IntlProvider } from 'react-intl';
import translations from 'i18n/locales';

import reducer from './reducer';

const initialState = {
  locale: 'ar',
  showAddToHome: false
};
export const appContext = React.createContext(initialState);

const AppProvider = ({ children }) => {
  const AppContextValue = useContext(appContext);
  const [state, dispatch] = useReducer(reducer, AppContextValue);

  const { locale } = state;
  const messages = translations[locale];

  return (
    <appContext.Provider value={{ state, dispatch }}>
      <IntlProvider locale={locale} key={locale} messages={messages}>
        {children}
      </IntlProvider>
    </appContext.Provider>
  );
};

export default AppProvider;
