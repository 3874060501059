import React, { useContext, useEffect, useState } from 'react'; // , { useContext, useState }
import { Col, Container, Row } from 'react-bootstrap';
import { fetchQuestions } from 'api/content';

import AudioIcon from 'components/UI/AudioIcon/AudioIcon';
import { appContext } from 'contexts/AppProvider';
import classes from './StepTwo.module.scss';
import { SmileyToggle } from './SvgButtons/SmileyToggle';

const StepTwo = ({ saveResults }) => {
  const {
    state: { locale }
  } = useContext(appContext);

  const [questions, setQuestions] = useState(null);

  useEffect(() => {
    (async () => {
      const data = await fetchQuestions({ locale });
      setQuestions(data.questions);
    })();
  }, []);

  return (
    <Container fluid className={classes.main}>
      <Row>
        <Col className={classes.questionText}>
          <div
            dangerouslySetInnerHTML={{
              __html: questions && questions[0].html_content
            }}
            className={classes.questionHtml}
          ></div>
          <div>
            <AudioIcon label="q1" />
          </div>
        </Col>
        <Col sm={3} lg={5}>
          <SmileyToggle name={'Question1'} saveResults={saveResults} />
        </Col>
      </Row>
      <Row>
        <Col className={classes.questionText}>
          <div
            dangerouslySetInnerHTML={{
              __html: questions && questions[1].html_content
            }}
            className={classes.questionHtml}
          ></div>
          <div>
            <AudioIcon label="q2" />
          </div>
        </Col>
        <Col sm={3} lg={5}>
          <SmileyToggle name={'Question2'} saveResults={saveResults} />
        </Col>
      </Row>
      <Row>
        <Col className={classes.questionText}>
          <div
            dangerouslySetInnerHTML={{
              __html: questions && questions[2].html_content
            }}
            className={classes.questionHtml}
          ></div>
          <div>
            <AudioIcon label="q3" />
          </div>
        </Col>
        <Col sm={3} lg={5}>
          <SmileyToggle name={'Question3'} saveResults={saveResults} />
        </Col>
      </Row>
      <Row>
        <Col className={classes.questionText}>
          <div
            dangerouslySetInnerHTML={{
              __html: questions && questions[3].html_content
            }}
            className={classes.questionHtml}
          ></div>
          <div>
            <AudioIcon label="q4" />
          </div>
        </Col>
        <Col sm={3} lg={5}>
          <SmileyToggle name={'Question4'} saveResults={saveResults} />
        </Col>
      </Row>
      <Row>
        <Col className={classes.questionText}>
          <div
            dangerouslySetInnerHTML={{
              __html: questions && questions[4].html_content
            }}
            className={classes.questionHtml}
          ></div>
          <div>
            <AudioIcon label="q5" />
          </div>
        </Col>
        <Col sm={3} lg={5}>
          <SmileyToggle name={'Question5'} saveResults={saveResults} />
        </Col>
      </Row>
    </Container>
  );
};

export default StepTwo;
