import request from './axiosRequest';

export const setUserConsent = () => {
  return request({
    url: `/surveys/consent`,
    method: 'PUT'
  });
};

export const setStep1Results = ({ age, cancerType, gender }) => {
  return request({
    url: `/surveys/step1`,
    method: 'PUT',
    data: {
      content: {
        age_range: age,
        cancer_type: cancerType,
        sex: gender
      }
    }
  });
};

export const setStep2Results = ({ Q1, Q2, Q3, Q4, Q5 }) => {
  return request({
    url: `/surveys/step2`,
    method: 'PUT',
    data: {
      content: {
        application_rating: Q1,
        content_rating: Q2,
        satisfaction_rating: Q3,
        treatment_rating: Q4,
        life_quality_rating: Q5
      }
    }
  });
};

export const getUserSurvey = id => {
  return request({
    url: `/users/user_survey/${id}`,
    method: 'GET'
  });
};

export const getUsersStats = locale => {
  return request({
    url: `/users/charts_data`,
    method: 'GET',
    params: { locale }
  });
};
