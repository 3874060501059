import { useCallback, useEffect, useRef, useState } from 'react';

const useAudio = (src, playState = true) => {
  const audioRef = useRef(null);
  const [playing, setPlaying] = useState(false);

  useEffect(() => {
    if (!src) return;
    const audio = new Audio(src);
    audioRef.current = audio;

    audio.addEventListener(
      'ended',
      () => {
        setPlaying(false);
      },
      false
    );

    if (playState) {
      audio.play();
      setPlaying(true);
    }
    return () => {
      return audio.pause();
    };
  }, [src]);

  const onPlayButtonClick = useCallback(
    e => {
      e.preventDefault();
      e.stopPropagation();
      const audio = audioRef.current;
      if (!playing) {
        audio.play();
        setPlaying(true);
      } else {
        audio.pause();
        setPlaying(false);
      }
    },
    [playing]
  );
  return {
    audioPlaying: playing,
    onPlayButtonClick
  };
};

export default useAudio;
